import React, { useState, useEffect, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import { getParameterByName, sendCDPFormData } from 'src/shared/helpers'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import AcceptTerms from 'src/components/AcceptTerms'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import sucessImg from '../images/success.png'

import { Container } from './style'

interface IFormValues {
  name: string;
  Email: string;
  Celular: string;
  DataNascimento: string;
  CPF: string;
  acceptTerms: boolean;
}

const Form = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dobra01: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Cartão Benefício INSS: até 70% do seu limite de crédito na sua mão!',
    element_name: 'Política de Privacidade',
    element_action: 'click button',
  }

  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ email, setEmail ] = useState('')
  const [ accept, setAccept ] = useState(false)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setError(false)
    setLoading(true)

    const { Celular, Email, DataNascimento, CPF, name }: IFormValues = data
    const formData = {
      Celular: Celular.replace(/[^\w\s]/gi, '').replace(' ', ''),
      UltimoNome: name.trim().split(/(?<=^\S+)\s/)[1],
      PrimeiroNome: name.trim().split(' ')[0],
      Email: Email.trim(),
      dataNascimento: DataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      CPF: CPF.replace(/[^\w\s]/gi, '').replace(' ', ''),
      Origem: 'App',
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lead-consignado-cartao-beneficio`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setSent(true)
      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Cartão Benefício INSS: até 70% do seu limite de crédito na sua mão!',
        element_action: 'click button',
        element_name: 'Cadastrar novo contato',
        step: 'success',
       })
      sendCDPFormData({ formName: 'Formulario cartão consignado', cpf: formData.CPF, email: formData.Email })
    } catch (e) {
      setError(true)
      setLoading(false)
      sendDatalayerEvent({
        section: 'dobra_01',
        section_name: 'Cartão Benefício INSS: até 70% do seu limite de crédito na sua mão!',
        element_action: 'click button',
        element_name: 'Cadastrar novo contato',
       })
    }
  }

  if (sent) {
    return (
      <Container>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12'>
            <img src={sucessImg} alt='imagem com circulo verde com uma mensagem de sucesso' />
            <h3 className='titleSuccessMessage'>
              Seu contato foi enviado para o Inter!
            </h3>
            <p className='subtitleSuccessMessage'>
              Agora, o próximo passo é com a gente! Aguarde nosso contato pelo telefone ou e-mail que você nos informou durante o preenchimento do formulário, para dar continuidade à sua contratação.
            </p>
            <p className='subtitleSuccessMessage'>Abraços da Equipe Inter!</p>
            <button
              title='Cadastrar novo contato'
              className='btn-success'
              onClick={() => {
                setSent(false)
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Cartão Benefício INSS: até 70% do seu limite de crédito na sua mão!',
                  element_action: 'click button',
                  element_name: 'Cadastrar novo contato',
                 })
              }}
            >
              Cadastrar novo contato
            </button>
          </div>
        </div>
      </Container>
    )
  }

  return (
    <Container>
      <form
        onSubmit={handleSubmit(sendForm)}
      >
        <span className='fs-16 lh-20 fw-600 text-grayscale--500 mb-0'>Envie seu contato</span>
        <p className='subtitle'>Preencha seus dados para que possamos entrar em contato.</p>

        <div className='row'>
          <div className='col-12 mb-1'>
            <label htmlFor='name'>Nome</label>
            <input
              className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
              ref={register({
                required: 'Campo Obrigatório.',
                validate: {
                  isName: (value: string) => Validations.name(value) || 'Insira o nome completo',
                },
              })}
              name='name'
              id='name'
              type='text'
              maxLength={80}
              placeholder='Informe o nome completo'
            />
            { errors.name && <p className='errorMessage'>{errors.name.message}</p> }
          </div>
          <div className='col-12 d-flex flex-column mb-1'>
            <label htmlFor='CPF'>CPF</label>
            <input
              className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
              ref={register({
                required: 'Por favor digite o CPF.',
                validate: {
                  isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                },
              })}
              name='CPF'
              id='CPF'
              type='text'
              placeholder='Digite seu CPF'
              onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('CPF', Masks.MaskCPF(event.currentTarget.value))}
            />
            { errors.CPF && <p className='errorMessage'>{errors.CPF.message}</p> }
          </div>
          <div className='col-12 d-flex flex-column mb-1'>
            <label htmlFor='DataNascimento'>Data de nascimento</label>
            <input
              className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
              ref={register({
                required: 'Por favor digite a sua data de nascimento.',
              })}
              name='DataNascimento'
              placeholder='00/00/0000'
              id='DataNascimento'
              type='text'
              maxLength={10}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('DataNascimento', Masks.MaskDATE(event.currentTarget.value))}
            />
            {errors.DataNascimento && <p className='errorMessage'>{errors.DataNascimento.message}</p>}
          </div>
          <div className='col-12 d-flex flex-column mb-1'>
            <label htmlFor='Email'>E-mail</label>
            <input
              className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
              ref={register({
                required: 'Campo Obrigatório.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'E-mail inválido',
                },
              })}
              name='Email'
              id='Email'
              value={email}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value)}
              maxLength={80}
              type='text'
              placeholder='banco@bancointer.com.br'
            />
            { errors.Email && <p className='errorMessage'>{errors.Email.message}</p> }
          </div>
          <div className='col-12 d-flex flex-column mb-1'>
            <label htmlFor='Celular'>Celular com DDD</label>
            <input
              className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
              ref={register({
                required: 'Por favor digite seu telefone celular.',
                pattern: {
                  value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                  message: 'Telefone inválido',
                },
              })}
              name='Celular'
              id='Celular'
              type='tel'
              placeholder='(xx) xxxx-xxxx'
              maxLength={15}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('Celular', Masks.MaskPHONE(event.currentTarget.value))}
            />
            { errors.Celular && <p className='errorMessage'>{errors.Celular.message}</p> }
          </div>

          <div className='col-12'>
            <AcceptTerms
              accept={accept}
              setAccept={setAccept}
              name='acceptTerms'
              label='Autorizo o Inter a entrar em contato sobre o Cartão Benefício INSS e estou de acordo com a'
              dataLayer={dobra01}
            />
          </div>
        </div>

        <div>
          <button
            type='submit'
            title='Quero o Cartão Benefício'
            disabled={!accept || loading}
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_01',
                section_name: 'Cartão Benefício INSS: até 70% do seu limite de crédito na sua mão!',
                element_action: 'click button',
                element_name: 'Quero o Cartão Benefício',
               })
            }}
          >
            {loading ? 'ENVIANDO...' : 'Quero o Cartão Benefício'}
          </button>
        </div>

        {error && (
          <div className='d-flex align-items-center mt-4'>
            <OrangeIcon size='MD' color='#FF2A44' icon='canceled' />
            <span className='pl-2 lh-22'>Ops! Ocorreu um erro ao enviar o contato. Tente novamente!</span>
          </div>)}
      </form>
    </Container>
  )
}

export default Form
