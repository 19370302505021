import { breakpoints, device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import BannerHeaderLg from '../../assets/images/banner-header-lg.png'
import BannerHeaderMd from '../../assets/images/banner-header-md.png'
import BannerHeaderMobile from '../../assets/images/banner-header-mobile.png'
import BannerHeaderXl from '../../assets/images/banner-header-xl.png'

export const Section = styled.section`
  background-image: url(${BannerHeaderMobile});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #F5F6FA;
  min-height: 854px;
  padding-top: 48px;
  padding-bottom: 48px;

  @media (min-width: ${breakpoints.md}) {
    min-height: 763px;
    background-image: url(${BannerHeaderMd});
    background-size: 400px;
    background-repeat: no-repeat;
    background-position-y: calc(100% + 50px);
    background-position-x: left;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 800px;
    background-image: url(${BannerHeaderLg});
    background-size: 550px;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: calc(100% + 85px);
    padding-bottom: 96px !important;
    padding-top: 48px !important;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 813px;
    background-image: url(${BannerHeaderXl});
    background-size: 640px;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: calc(100% + 17px);
    padding-bottom: 96px !important;
    padding-top: 48px !important;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    background-image: none;
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }

  .container {
    @media (min-width: ${breakpoints.xxxl}) {
      min-height: 813px;
      background-image: url(${BannerHeaderXl});
      background-size: 720px;
      background-repeat: no-repeat;
      background-position-x: left;
      background-position-y: calc(100% + 17px);
    }
  }

  h1 {
    font-family: "Sora", Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #161616;

    @media ${device.tablet} {
      font-size: 32px;
      line-height: 40px;
    }

    @media ${device.desktopLG} {
      font-size: 40px;
      line-height: 50px;
    }
  }

  .header-subtitle {
    font-family: "Inter", Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #6A6C72;
    margin-top: 16px;
    margin-bottom: 24px !important;

    @media ${device.desktopLG} {
      font-size: 18px;
      line-height: 22px;
    }
  }
`
