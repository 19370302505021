import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  padding: 24px 16px;
  box-shadow: 0px 24px 32px -8px rgba(22, 22, 22, 0.08);
  border-radius: 16px;

  @media ${device.tablet} {
    padding: 24px 24px;
  }

  label {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: ${grayscale[500]};
    margin-bottom: 4px;
  }

  input {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    background-color: ${grayscale[100]};
    border-radius: 8px;
  }

  .subtitle {
    font-family: "Inter", Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #161616;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .errorMessage {
    font-size: 12px;
    line-height: 15px;
    color: ${orange.extra};
    margin-bottom: 0;
    margin-top: 2px;
  }

  .titleSuccessMessage {
    font-family: "Sora", Helvetica, sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #161616;
    margin-bottom: 32px;
  }

  .subtitleSuccessMessage {
    font-family: "Inter", Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  img {
    margin-bottom: 48px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  button {
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 8px;
    background-color: ${orange.extra};
    color: ${grayscale[100]};
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;

    &:disabled {
      background-color: ${grayscale[200]};
    }
  }

  .btn-success {
    background: none;
    border: 1px solid #FFB46E;
    border-radius: 8px;
    color: #FF7A00;
  }
`

export const BoxedTitle = styled.div``
